<template>
  <div>
    <treatment-history-detail :backRoute="backRoute" patientDetailRoute="OrgOwnerPatientDetail" />
  </div>
</template>
<script>
import PatientTreatmentHistoryDetails from "../../general-component/treatment-history/TreatmentDetails2";
export default {
  name: "FranchiePatientDetails",
  components: {
    "treatment-history-detail": PatientTreatmentHistoryDetails
  },
  data() {
    return {
      backRoute: "FranchisePatientDetail"
    };
  },
  created() {
    if (this.$route.query.backRoute)
      this.backRoute = this.$route.query.backRoute;
  },
  beforeRouteLeave(to, from, next) {
    const popup = document.getElementById("editPatientDetailsDrawer");
    if (popup && window.getComputedStyle(popup).display !== "none"){
      next(false);
    }
    else {
      next();
    }
  },
};
</script>
